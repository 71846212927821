import React from 'react'
import PropTypes from 'prop-types'

import emoji from 'react-easy-emoji'

import blogimg from '../images/9585B058C38D12016EFFFEFCDE8C15C2D866C257.jpg'
import aboutimg from '../images/603C805ABCC37CCDDA80E758BCFB31E850696D7C.jpg'
import caffineimg from '../images/A2A0AEBF34A80258C8BCC7064F8DAF826DD5B252.png'

class Main extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      message: '',
      link: ''
    }
    this.updateState = this.updateState.bind(this)
  }
  updateState (e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  render () {
    let close = (
      <div
        className='close'
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id='main'
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>
        <article
          id='blog'
          className={`${this.props.article === 'blog' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}>
          <h2 className='major'>Blogs?</h2>
          <span className='image main'>
            <img src={blogimg} alt='' />
          </span>
          <p>{emoji('🦇')} Bats</p>
          {close}
        </article>

        <article
          id='caffine'
          className={`${this.props.article === 'caffine' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}>
          <h2 className='major'>Caffine!</h2>
          <span className='image main'>
            <img src={caffineimg} alt='' />
          </span>
          <p>Caffine the Life giving substance</p>
          {close}
        </article>

        <article
          id='about'
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}>
          <h2 className='major'>About</h2>
          <span className='image main'>
            <img src={aboutimg} alt='' />
          </span>
          <p>About What?</p>
          {close}
        </article>

        <article
          id='games'
          className={`${this.props.article === 'games' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}>
          <h2 className='major'>Games</h2>
          <span className='image main'>
            <img src='' alt='' />
          </span>
          <p>About What?</p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired
}

export default Main
