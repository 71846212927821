import React from 'react'
import PropTypes from 'prop-types'

import bat from '../images/bat-silhouette.png'

const Header = props => (
  <header id='header' style={props.timeout ? { display: 'none' } : {}}>
    <div className='logo'>
      <img src={bat} alt='bat' />
    </div>
    <div className='content'>
      <div className='inner'>
        <h1>Something</h1>
        <p>Something should go here... someday</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            className='nonbor'
            onClick={() => {
              props.onOpenArticle('blog')
            }}>
            blog
          </button>
        </li>
        <li>
          <button
            className='nonbor'
            onClick={() => {
              props.onOpenArticle('games')
            }}>
            Games
          </button>
        </li>

        <li>
          <button
            className='nonbor'
            onClick={() => {
              props.onOpenArticle('caffine')
            }}>
            caffine
          </button>
        </li>
        <li>
          <button
            className='nonbor'
            onClick={() => {
              props.onOpenArticle('about')
            }}>
            About
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
}

export default Header
